/* eslint-disable */
import Konva from "konva";
import YNode  from "./YNode";

export default class YNodeDefault  extends YNode {
    
    constructor(id = null) {
        super();

        if (id) {
            this._id = id;
        }
    }

    make() {
        var maxValue = Math.max(this.num_out, this.num_in);
        if (this.num_out > 1) {
            this.distanceOut = 30;
        }
        if (this.num_in > 1) {
            this.distanceIn = 30;
        }
        this.heightDefault *= maxValue;
        console.log('Style', this.style);
        if(this.style.icon && this.style.label) {
            var icon = {};
            if(this.style.isSource) {
                var SOURCE = this.style.icon;
                Konva.Image.fromURL(SOURCE, (imageNode) => {
                    this.add(imageNode);
                    imageNode.setAttrs({
                        width: 40,
                        height: 40,
                        x: this.widthDefault / 2 - 20,
                        y: this.heightDefault / 2 - 40,
                    });
                    icon = imageNode;
                });
            } else {
                icon = new Konva.Text({
                    x: 10,
                    y: 8,
                    padding: 10,
                    align: 'center',
                    width: 90,
                    height: 40,
                    text: this.style.icon,
                    fontSize: 25,
                    fontFamily: 'Material Symbols Outlined',
                    fill: this.style.color ? this.style.color : '#252525',
                });
                icon.listening(false);
                this.add(icon);
            }
            let x = 75;
            let y = 20;
            if (this.style.isSource) {
                x = this.widthDefault / 2 - 70;
                y = this.heightDefault / 2 + 20;
            }
            var title = new Konva.Text({
                x: x,
                y: y,
                text: this.style.label,
                fontSize: 18,
                fontFamily: 'Calibri',
                fill: this.style.color ? this.style.color : '#252525',
            });
            title.listening(false);
            if (title.getAttr('width') > 10) {
                this.widthDefault = title.getAttr('width') + 130;
            }

            this.add(title);
        }

        this.render();
    }
}