/* eslint-disable */
import Konva from 'konva';
import Loader from '@/plugins/loader';
import axios from '@/plugins/axios';
import YNodeDefault from './nodes/YNodeDefault';
import YNodeWelcome from './nodes/YNodeWelcome';
const webApi = axios();

export default class FlowBuilder {
    constructor(container, width = 800, height = 500) {
        this.container = container;
        this.stage = null;
        this.gridLayer = null;
        this.stageRect = null;
        this.viewRect = null;
        this.viewPort = null;
        this.containerEle = null;
        this.fullRect = null;
        this.gridAdjust = null;
        this.gridRect = null;
        this.width = width;
        this.height = height;
        this.stepSize = 40; // set a value for the grid step gap.
        this.layer = null;
        this.drawingLine = false;
        this.connections = [];
        this.selectionRectangle = null;
        this.events = {};
        this.newScale = 0;
        this.nodes = {};
        this.currentScale = 6;
        this.selecting = false;
        this.x1;
        this.y1;
        this.x2;
        this.y2;
        this.tr = null;
        this.keyCTRL = false;
        this.keyALT = false;
        this.errorsShapes = [];
        this.drawingLineId = 0;
    }

    /* Events */
    on(event, callback) {
        // Check if the callback is not a function
        if (typeof callback !== 'function') {
            console.error(`The listener callback must be a function, the given type is ${typeof callback}`);
            return false;
        }
        // Check if the event is not a string
        if (typeof event !== 'string') {
            console.error(`The event name must be a string, the given type is ${typeof event}`);
            return false;
        }
        // Check if this event not exists
        if (this.events[event] === undefined) {
            this.events[event] = {
                listeners: [],
            };
        }
        this.events[event].listeners.push(callback);
    }

    removeListener(event, callback) {
        // Check if this event not exists

        if (!this.events[event]) return false;

        const listeners = this.events[event].listeners;
        const listenerIndex = listeners.indexOf(callback);
        const hasListener = listenerIndex > -1;
        if (hasListener) listeners.splice(listenerIndex, 1);
    }

    dispatch(event, details) {
        // Check if this event not exists
        if (this.events[event] === undefined) {
            // console.error(`This event: ${event} does not exist`);
            return false;
        }
        this.events[event].listeners.forEach((listener) => {
            listener(details);
        });
    }

    render() {
        this.stage = new Konva.Stage({
            container: this.container,   // id of container <div>
            width: this.width,
            height: this.height,
            draggable: true,
        });
        this.layer = new Konva.Layer();
        this.gridLayer = new Konva.Layer({
            draggable: true,
            x: 0,
            y: 0,
        });

        // add the shape to the layer

        //this.drawGrid();

        this.stage.add(this.gridLayer);
        this.stage.add(this.layer);

        this.generateSelect();
        this.eventsListener();
    }

    generateSelect() {
        this.tr = new Konva.Transformer({
            resizeEnabled: false,
            rotateEnabled: false,
            rotateLineVisible: false,
            borderStroke: '#752de6',
            borderStrokeWidth: 3,
            borderDash: [10, 10],
            padding: 10,
            enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right']
        });
        this.layer.add(this.tr);

        this.selectionRectangle = new Konva.Rect({
            fill: 'rgba(0,0,255,0.5)',
            visible: false,
        });
        this.layer.add(this.selectionRectangle);
        this.selectionRectangle.zIndex(10);
        this.selectionRectangle.draw();
    }

    makeDelete(pos_x = 90, pos_y = -10, name = null) {
        let tooltip = new Konva.Label({
            name,
            width: 90,
            x: pos_x,
            y: pos_y,
            opacity: 1,
            scale: {
                cursor: 'pointer'
            }
        });
        tooltip.add(
            new Konva.Tag({
                fill: 'white',
                pointerDirection: 'down',
                pointerWidth: 10,
                pointerHeight: 10,
                lineJoin: 'round',
                shadowColor: 'black',
                stroke: '#E5E5E5',
                shadowColor: '#ccc',
                shadowBlur: 5,
                shadowOffset: { x: 2, y: 2 },
                shadowOpacity: 0.3,
                strokeWidth: 4,
            })
        );
        var icon = new Konva.Text({
            padding: 10,
            align: 'center',
            width: 90,
            height: 40,
            text: 'delete',
            fontSize: 25,
            fontFamily: 'Material Symbols Outlined',
            fill: 'red',
        });
        icon.on('mouseover', (e) => {
            this.stage.container().style.cursor = 'pointer';
        });
        icon.on('mouseout', (e) => {
            this.stage.container().style.cursor = 'default';
        });
        tooltip.add(icon);

        tooltip.on('click', (e) => {
            let name = e.target.parent.getAttr('name');
            if (name.includes('delete_node')) {
                var id = name.replace('delete_node_', '');
                let itemId = '.node_' + id;
                var element = this.stage.findOne(itemId);

                if (this.connections.length > 0) {
                    let filterConnections = []
                    this.connections.filter((con, i) => {
                        if ((parseInt(con.node_in) === parseInt(id)) || (parseInt(con.node_out) === parseInt(id))) {
                            filterConnections.push({
                                con,
                                i
                            })
                        }
                    });

                    if (filterConnections.length > 0) {
                        filterConnections.forEach((connection) => {
                            let output = this.stage.findOne('.' + connection.con.output_name);
                            if (output) {
                                output.setAttr('fill', '#fff');
                            }

                            let input = this.stage.findOne('.' + connection.con.input_name);
                            if (input) {
                                input.setAttr('fill', '#fff');
                            }

                            let nodeInput = this.nodes[connection.con.node_in];
                            let nodeOutput = this.nodes[connection.con.node_out];

                            if (nodeInput) {
                                nodeInput.inputs[`input_${connection.con.input_index}`].connections.forEach((con, i) => {
                                    if (con.node == connection.con.node_out) {
                                        nodeInput.inputs[`input_${connection.con.input_index}`].connections.splice(i, 1);
                                    }
                                });
                            }

                            if (nodeOutput) {
                                nodeOutput.outputs[`output_${connection.con.output_index}`].connections.forEach((con, i) => {
                                    if (con.node == connection.con.node_in) {
                                        nodeOutput.outputs[`output_${connection.con.output_index}`].connections.splice(i, 1);
                                    }
                                });
                            }

                            connection.con.line.remove();
                            this.connections.splice(connection.i, 1);
                        });
                    }
                }

                delete this.nodes[id];
                element.remove();
            }

            if (name.includes('delete_line')) {
                console.log('Name line', name);
                let id = name.replace('delete_line_', '');
                if (this.connections.length > 0) {
                    let index = 0;
                    console.log(this.connections);
                    let connection = this.connections.find((con, i) => {
                        if (con != undefined) {
                            index = i;
                            return con.id === parseInt(id);
                        }
                    });
                    
                    if (connection) {
                        let nodeInput = this.nodes[connection.node_in];
                        let nodeOutput = this.nodes[connection.node_out];

                        this.dispatch('disconnected', {
                            node_in: connection.node_in,
                            node_out: connection.node_out,
                        });
                        nodeInput.inputs[`input_${connection.input_index}`].connections.forEach((con, i) => {
                            if (con.node == connection.node_out) {
                                nodeInput.inputs[`input_${connection.input_index}`].connections.splice(i, 1);
                            }
                        });

                        nodeOutput.outputs[`output_${connection.output_index}`].connections.forEach((con, i) => {
                            if (con.node == connection.node_in) {
                                nodeOutput.outputs[`output_${connection.output_index}`].connections.splice(i, 1);
                            }
                        });

                        let output = this.stage.findOne('.' + connection.output_name);
                        output.setAttr('fill', '#fff');
                        let input = this.stage.findOne('.' + connection.input_name);
                        input.setAttr('fill', '#fff');

                        connection.line.remove();
                        this.connections.splice(index, 1);
                    }
                }
            }

            tooltip.remove();
            this.stage.container().style.cursor = 'default';
            this.dispatch('deleted');
        });
        setTimeout(() => {
            tooltip.remove();
            this.stage.container().style.cursor = 'default';
        }, 1000);
        return tooltip;
    }

    makeMessage(pos_x = 90, pos_y = -10, text = '') {
        var title = new Konva.Text({
            padding: 10,
            align: 'center',
            text: text,
            fontSize: 13,
            fill: '#fff',
        });

        let tooltip = new Konva.Label({
            width: 500,
            x: pos_x,
            y: pos_y,
            opacity: 1,
            scale: {
                cursor: 'pointer'
            }
        });
        tooltip.add(
            new Konva.Tag({
                fill: '#721c24',
                pointerDirection: 'down',
                pointerWidth: 10,
                pointerHeight: 10,
                lineJoin: 'round',
                shadowColor: 'black',
                stroke: '#fff',
                shadowColor: '#ccc',
                shadowBlur: 5,
                shadowOffset: { x: 2, y: 2 },
                shadowOpacity: 0.3,
                strokeWidth: 2,
            })
        );

        title.listening(false);
        tooltip.add(title);
        tooltip.listening(false);
        return tooltip;
    }

    addNode(x, y, name, data, num_in = 0, num_out = 1, style = {}, id = null, outputsInit = {}, inputsInit = {}, checkOverlap = true) {
        var shape;
        switch (name) {
            case 'welcome':
                shape = new YNodeWelcome(id);
                shape.name(name);
                shape.x(x);
                shape.y(y);
                shape.style = {
                    ...style,
                    label: 'Passo inicial'
                };
                shape.num_in = num_in;
                shape.num_out = num_out;
                shape.inputsInit = inputsInit;
                shape.outputsInit = outputsInit;
                shape.make();
                break;
            default:
                shape = new YNodeDefault(id);
                shape.name(`node_${shape._id}`);
                shape.x(x);
                shape.y(y);
                shape.style = style;
                shape.num_in = num_in;
                shape.num_out = num_out;
                shape.inputsInit = inputsInit;
                shape.outputsInit = outputsInit;
                shape.make();
                break;
        }

        shape.event('output_down', (event) => {
            let index = event.output.getAttr('index');
            this.drawingLine = true;
            const posMouse = event.output.getStage().getRelativePointerPosition();
            const posTarget = event.output.getAbsolutePosition();
            var points = [posMouse.x, posMouse.y];
            var line = new Konva.Line({
                name: `line_node_${event.node._id}_out_${event.output._id}`,
                points: points,
                stroke: '#ccc',
                strokeWidth: 4,
                lineCap: 'round',
                lineJoin: 'round',
                fillPatternX: 10,
                //dash: [20, 20],
            });
            line.perfectDrawEnabled(false);

            this.connections[line._id] = {
                id: line._id,
                node_out: event.node._id,
                line: line,
                points: [posMouse.x, posMouse.y],
                output_name: event.output.getAttr('name'),
                output_index: index,
                output_name: `output_${index}_node_${event.node._id}`,
            };
            this.drawingLineId = line._id;

            this.layer.add(line);
            line.zIndex(0);
            line.on('mouseover', (e) => {
                e.target.attrs.stroke = '#03fcd3';
                line.zIndex(0);
                e.target.draw();
            });
            line.on('click', (e) => {
                if (!this.drawingLine) {
                    let deleteConnection = this.stage.findOne(`.delete_line_${event.output._id}`);
                    if (deleteConnection == undefined) {
                        deleteConnection = this.makeDelete(e.target.getRelativePointerPosition().x, e.target.getRelativePointerPosition().y, `delete_line_${e.target._id}`);
                        this.layer.add(deleteConnection);
                    }
                }
            });
            line.on('mouseout', function (e) {
                e.target.attrs.stroke = '#E5E5E5';
                line.zIndex(0);
                e.target.draw();
            });
        });

        shape.event('input_up', (event) => {
            const posMouse = event.input.getStage().getRelativePointerPosition();
            const posTarget = event.input.getAbsolutePosition();
            var points = [posMouse.x, posMouse.y];
            var oncircle = event.input;
            const isCircle = event.input instanceof Konva.Circle;
            if (this.drawingLine) {
                let node_out = this.connections[this.drawingLineId].node_out;
                let output_name = this.connections[this.drawingLineId].output_name;
                let output_index = this.connections[this.drawingLineId].output_index;
                let index = event.input.getAttr('index');
                let connections = this.nodes[node_out].outputs['output_' + output_index].connections;

                console.log('Connection out', connections);
                let existCon = false;
                if (connections.length > 0) {
                    connections.forEach(con => {
                        let inputName = 'input_' + index;
                        if(con.output == inputName && con.node == oncircle.parent._id) {
                            this.connections[this.drawingLineId].line.remove();
                            let index = this.connections.indexOf(this.drawingLineId)
                            this.connections.splice(index, 1);
                            existCon = true;
                            console.log('Existe');
                        }
                    });
                }

                if(!existCon) {
                    const lastLine = this.connections[this.drawingLineId];
                    lastLine.points = [
                        lastLine.points[0],
                        lastLine.points[1],
                        event.input.x(),
                        event.input.y(),
                    ];
                    this.connections[this.drawingLineId].input_name = event.input.getAttr('name');
                    this.connections[this.drawingLineId].input_index = index;
                    this.connections[this.drawingLineId].node_in = oncircle.parent._id;

                    this.nodes[oncircle.parent._id].inputs['input_' + index].connections.push(
                        {
                            node: node_out,
                            input: 'output_' + output_index,
                            output_name: output_name
                        }
                    );
                    this.nodes[this.connections[this.drawingLineId].node_out].outputs['output_' + output_index].connections.push(
                        {
                            node: oncircle.parent._id,
                            output: 'input_' + index,
                            input_name: `input_${index}_node_${shape._id}`
                        }
                    );
                    event.input.setAttr('fill', '#03fcd3');
                    let outputCircle = this.stage.findOne('.' + output_name);
                    outputCircle.setAttr('fill', '#03fcd3');

                    lastLine.line.setAttr('name', `node_in_${shape._id}_input_${index}_node_out_${node_out}_output_${output_index}`);

                    this.dispatch('connected', {
                        node_in: oncircle.parent._id,
                        node_out: node_out,
                        output_class: `output_${output_index}`,
                    });
                }
            }
            this.drawingLine = false;
        });

        shape.on('mousemove', (e) => {
            if (this.drawingLine) {
                e.target.parent.setDraggable(false);
            }
        });
        shape.on('mouseup', (e) => {
            const oncircle = e.target instanceof Konva.Circle;
            shape.setDraggable(true);
            if (this.drawingLine) {
                if (!oncircle || (oncircle && e.target.getAttr('name').includes('output'))) {
                    this.connections[this.drawingLineId].line.remove();
                    let index = this.connections.indexOf(this.drawingLineId)
                    this.connections.splice(index, 1);
                }
                this.drawingLine = false;
            }
        });

        shape.on('click', (e) => {
            const oncircle = e.target instanceof Konva.Circle;
            shape.setDraggable(true);

            if (!oncircle) {
                this.dispatch('clicked', {
                    id: e.target.parent._id,
                });
            }

            if (this.drawingLine) {
                this.connections[this.drawingLineId].line.remove();
                let index = this.connections.indexOf(this.drawingLineId)
                this.connections.splice(index, 1);
                this.drawingLine = false;
            }
        });

        shape.on('dragmove', (e) => {
            let target = e.target;
            let node = this.nodes[target._id];
            this.nodes[target._id].pos_x = target.getAttr('x');
            this.nodes[target._id].pos_y = target.getAttr('y');
            this.dispatch('move', {
                id: target._id,
                x: target.getAttr('x'),
                y: target.getAttr('y')
            });

            if (node.inputs) {
                let inputs = Object.values(node.inputs);
                if (inputs.length > 0) {
                    inputs.forEach((input) => {
                        if (input.connections.length > 0) {
                            input.connections.forEach((connection) => {
                                let line_name = `node_in_${target._id}_input_${input.index}_node_out_${connection.node}_${connection.input}`
                                let inputCircle = this.stage.findOne('.' + input.name);
                                let outputCircle = this.stage.findOne('.' + connection.input + '_node_' + connection.node);
                                let line = this.stage.findOne('.' + line_name);
                                let stageX = this.stage.position().x;
                                let stageY = this.stage.position().y;

                                connection.output_name = connection.input + '_node_' + connection.node;

                                var outPutX = outputCircle.getAttr('x') + outputCircle.parent.getAttr('x');
                                var outPutY = outputCircle.getAttr('y') + outputCircle.parent.getAttr('y');
                                var inputX = inputCircle.getAttr('x') + target.getAttr('x');
                                var inputY = inputCircle.getAttr('y') + target.getAttr('y');

                                if (line) {
                                    line.points([outPutX, outPutY, inputX, inputY]);
                                    line.sceneFunc((context, shape) => {
                                        this.lineCurve(inputX, outPutX, inputY, outPutY, context, shape);
                                    });
                                }
                            });
                        }
                    })
                }
            }
            if (node.outputs) {
                let outputs = Object.values(node.outputs);
                if (outputs.length > 0) {
                    outputs.forEach((output) => {
                        if (output.connections.length > 0) {
                            output.connections.forEach((connection) => {
                                let line_name = `node_in_${connection.node}_${connection.output}_node_out_${target._id}_output_${output.index}`
                                let inputCircle = this.stage.findOne('.' + connection.output + '_node_' + connection.node);
                                let inputNode = this.layer.findOne('#' + connection.node);
                                let outputCircle = this.stage.findOne('.' + output.name);
                                connection.input_name = connection.output + '_node_' + connection.node;

                                let line = this.stage.findOne('.' + line_name);

                                var stageX = this.stage.position().x;
                                var stageY = this.stage.position().y;

                                var outPutX = outputCircle.getAttr('x') + target.getAttr('x');
                                var outPutY = outputCircle.getAttr('y') + target.getAttr('y');
                                var inputX = inputCircle.getAttr('x') + inputCircle.parent.getAttr('x');
                                var inputY = inputCircle.getAttr('y') + inputCircle.parent.getAttr('y');

                                if (line) {
                                    line.points([outPutX, outPutY, inputX, inputY]);
                                    line.sceneFunc((context, shape) => {
                                        this.lineCurve(inputX, outPutX, inputY, outPutY, context, shape);
                                    });
                                }
                            });
                        }
                    })
                }
            }
            //myArray.find(x => x.id === '45')
        });

        shape.container.on('mouseover', (e) => {
            e.target.attrs.stroke = '#00D6A366';
            if (e.target.parent.getAttr('name') != 'welcome') {
                let deleteNode = this.stage.findOne(`.delete_node_${e.target.parent._id}`);
                if (deleteNode == null) {
                    deleteNode = this.makeDelete(shape.container.getAttr('width') / 2, -10, `delete_node_${e.target.parent._id}`);
                    e.target.parent.add(deleteNode);
                }
            }
            e.target.zIndex(0);
            e.target.draw();
        });
        shape.container.on('mouseout', function (e) {
            e.target.attrs.stroke = '#E5E5E5';
            e.target.zIndex(0);
            e.target.draw();
        });

        this.layer.add(shape);
        this.layer.draw();

        this.containerEle = document.getElementById(this.container);
        this.viewPort = { x: 0, y: 0, width: this.containerEle.clientWidth, height: this.containerEle.clientHeight };
        let outside = 0;
        if (!this.hasOverlap(this.viewPort, shape.container.getClientRect()) && checkOverlap) {
            outside = 1;
        }

        if (outside > 0) {
            shape.visible(false);
            shape.listening(false);
            shape.container.listening(false);
        }

        //Make object json
        return this.nodes[shape._id] = {
            id: shape._id,
            name: name,
            data,
            inputs: shape.inputs,
            pos_x: x,
            pos_y: y,
            outputs: shape.outputs,
            style
        };
    }

    hasOverlap(r1, r2) {

        let w1 = r1.width, h1 = r1.height;
        let w2 = r2.width, h2 = r2.height;

        let diff = {
            x: Math.abs((r1.x + w1 / 2) - (r2.x + w2 / 2)),
            y: Math.abs((r1.y + h1 / 2) - (r2.y + h2 / 2))
        };

        let compWidth = (r1.width + r2.width) / 2,
            compHeight = (r1.height + r2.height) / 2;

        let hasOverlap = (((diff.x - 1000) <= compWidth) && ((diff.y - 1000) <= compHeight))

        return hasOverlap;
    }

    zoom(direction = 1) {
        // stop default scrolling
        let scales = [5, 4, 3, 2.5, 2, 1.5, 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0.05]

        var oldScale = this.stage.scaleX();

        var mousePointTo = {
            x: (this.stage.x()) / oldScale,
            y: (this.stage.y()) / oldScale,
        };

        if (direction > 0) {
            this.currentScale = this.currentScale > 0 ? this.currentScale - 1 : this.currentScale;
        }
        else {
            this.currentScale = this.currentScale < scales.length - 1 ? this.currentScale + 1 : this.currentScale;
        }

        if (direction == 0) {
            this.currentScale = 6;
        }

        this.newScale = scales[this.currentScale];

        this.stage.scale({ x: this.newScale, y: this.newScale });

        var newPos = {
            x: mousePointTo.x * this.newScale,
            y: mousePointTo.y * this.newScale,
        };

        this.stage.position(newPos);

        this.stage.draw();
        //this.drawGrid();
    }

    eventsListener() {
        let x = new Konva.Layer();
        var scaleBy = 1.01;
        let scales = [5, 4, 3, 2.5, 2, 1.5, 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0.05]
        this.stage.on('wheel', (e) => {
            // stop default scrolling
            e.evt.preventDefault();

            var oldScale = this.stage.scaleX();
            var pointer = this.stage.getPointerPosition();

            var mousePointTo = {
                x: (pointer.x - this.stage.x()) / oldScale,
                y: (pointer.y - this.stage.y()) / oldScale,
            };

            // how to scale? Zoom in? Or zoom out?
            let direction = e.evt.deltaY > 0 ? -1 : 1;

            // when we zoom on trackpad, e.evt.ctrlKey is true
            // in that case lets revert direction
            if (e.evt.ctrlKey) {
                direction = -direction;
            }

            if (direction > 0) {
                this.currentScale = this.currentScale > 0 ? this.currentScale - 1 : this.currentScale;
            }
            else {
                this.currentScale = this.currentScale < scales.length - 1 ? this.currentScale + 1 : this.currentScale;
            }

            this.newScale = scales[this.currentScale];

            this.stage.scale({ x: this.newScale, y: this.newScale });

            var newPos = {
                x: pointer.x - mousePointTo.x * this.newScale,
                y: pointer.y - mousePointTo.y * this.newScale,
            };

            this.stage.position(newPos);

            this.stage.draw();
            //this.drawGrid();

            this.layer.children.forEach((children) => {
                const ongroup = children instanceof Konva.Group;
                if (ongroup) {
                    this.viewPort = { x: 0, y: 0, width: this.containerEle.clientWidth, height: this.containerEle.clientHeight };
                    let outside = 0;
                    if (!this.hasOverlap(this.viewPort, children.children[0].getClientRect())) {
                        outside = 1;
                    }

                    if (outside > 0) {
                        children.visible(false);
                        children.listening(false);
                        children.children[0].listening(false);
                    } else {
                        children.visible(true);
                        children.listening(true);
                        children.children[0].listening(true);
                    }
                }
            });
        });
        this.stage.on('mouseup', (e) => {
            this.stage.setDraggable(true);
            const oncircle = e.target instanceof Konva.Circle;
            if (this.drawingLine) {
                if (!oncircle) {
                    this.connections[this.connections.length - 1].line.remove();
                    this.connections.splice(this.connections.length - 1, 1);
                }
            }
            if (this.selecting) {
                this.selectionRectangle.visible(false);
                var shapes = this.stage.find('Group');

                var box = this.selectionRectangle.getClientRect();
                var selected = shapes.filter((shape) =>
                    Konva.Util.haveIntersection(box, shape.getClientRect())
                );
                //remove all not group
                let selections = [];
                selected.forEach((item) => {
                    let hasGroup = item instanceof Konva.Label;
                    if (!hasGroup) {
                        selections.push(item);
                    }
                });

                this.tr.nodes(selections);
            }
            this.selecting = false;
            this.drawingLine = false;
        });
        this.stage.on('click', (e) => {
            const onstage = e.target instanceof Konva.Stage;

            if (e.target === this.stage) {
                this.tr.nodes([]);
                this.dispatch('click');
            }
        });
        this.stage.on('mousedown', (e) => {
            const oncircle = e.target instanceof Konva.Circle;
            if (e.target == this.stage) {
                if (this.keyCTRL) {
                    this.stage.setDraggable(false);
                    this.x1 = this.stage.getRelativePointerPosition().x;
                    this.y1 = this.stage.getRelativePointerPosition().y;
                    this.x2 = this.stage.getRelativePointerPosition().x;
                    this.y2 = this.stage.getRelativePointerPosition().y;

                    this.selectionRectangle.width(0);
                    this.selectionRectangle.height(0);

                    this.selecting = true;
                }
            }

            if (!oncircle) {
                return;
            }
        });
        this.stage.on('mousemove', (e) => {
            if (this.drawingLine) {
                this.stage.setDraggable(false);
                const posMouse = e.target.getStage().getRelativePointerPosition();
                const lastLine = this.connections[this.connections.length - 1];
                lastLine.points = [lastLine.points[0], lastLine.points[1], posMouse.x, posMouse.y];
                lastLine.line.points(lastLine.points);
                lastLine.line.sceneFunc((context, shape) => {
                    this.lineCurve(posMouse.x, lastLine.points[0], posMouse.y, lastLine.points[1], context, shape);
                });
            }
            if (this.selecting) {
                this.x2 = this.stage.getRelativePointerPosition().x;
                this.y2 = this.stage.getRelativePointerPosition().y;

                this.selectionRectangle.setAttrs({
                    visible: true,
                    x: Math.min(this.x1, this.x2),
                    y: Math.min(this.y1, this.y2),
                    width: Math.abs(this.x2 - this.x1),
                    height: Math.abs(this.y2 - this.y1),
                });
            }
        });
        this.stage.on('dragend', (e) => {
            //this.drawGrid();
            this.layer.children.forEach((children) => {
                const ongroup = children instanceof Konva.Group;
                if (ongroup) {
                    this.viewPort = { x: 0, y: 0, width: this.containerEle.clientWidth, height: this.containerEle.clientHeight };
                    let outside = 0;
                    if (!this.hasOverlap(this.viewPort, children.children[0].getClientRect())) {
                        outside = 1;
                    }

                    if (outside > 0) {
                        children.visible(false);
                        children.listening(false);
                        children.children[0].listening(false);
                    } else {
                        children.visible(true);
                        children.listening(true);
                        children.children[0].listening(true);
                    }
                }
            });
        });

        var con = this.stage.container();
        con.addEventListener('dragover', (e) => {
            e.preventDefault(); // !important
        });

        con.tabIndex = 1;

        con.addEventListener('keydown', (e) => {
            e.preventDefault();
            switch (e.keyCode) {
                case 17:
                    this.keyCTRL = true;
                    break;
                case 18:
                    this.keyALT = true;
                default:
                    break;
            }

        });

        con.addEventListener('keyup', (e) => {
            e.preventDefault();
            this.keyCTRL = false;
            this.keyALT = false;
            this.stage.setDraggable(true);
            this.selecting = false;
        });

        con.addEventListener('drop', (e) => {
            e.preventDefault();
            this.stage.setPointersPositions(e);
            this.dispatch('drop', {
                name: e.dataTransfer.getData('node'),
                x: this.stage.getRelativePointerPosition().x,
                y: this.stage.getRelativePointerPosition().y
            });
        });
    }

    updateLines() {
    }

    unScale(val) {
        return (val / this.stage.scaleX());
    }

    lineCurve(x1, x2, y1, y2, context, shape) {
        const width = x1 - x2;
        const height = y1 - y2;
        const dir = Math.sign(height);
        const radius = Math.min(20, Math.abs(height / 2), Math.abs(width / 2));
        context.beginPath();
        context.moveTo(x2, y2);
        if (Math.abs(height) <= 10) {
            context.lineTo(x1, y1);
        } else {
            context.bezierCurveTo(
                (parseInt(Math.abs(width)) <= 200) ? x2 + 100 : x2 + 200,
                y2 + 20,
                (parseInt(Math.abs(width)) <= 200) ? x1 - 100 : x1 - 200,
                y1 + 20,
                x1,
                y1
            );
        }

        /*context.moveTo(x2, y2);
        context.lineTo(x2 + width / 2 - 20, y2);
        context.quadraticCurveTo(
            x2 + width / 2,
            y2,
            x2 + width / 2,
            y2 + dir * radius
        );
        context.lineTo(x2 + width / 2, y1 - dir * radius);
        context.quadraticCurveTo(
            x2 + width / 2,
            y1,
            x2 + width / 2 + radius,
            y1
        );
        context.lineTo(x1, y1);*/
        context.fillStrokeShape(shape);
    }

    export() {
        this.dispatch('export', this.nodes);
        return this.nodes;
    }

    import(data, source = '', render = true, old = false) {
        if (render)
            this.clear();
        this.nodes = JSON.parse(JSON.stringify(data));
        if (render)
            this.load(this.nodes, source, old);
        this.dispatch('import', 'import');
    }

    load(param = {}, source, old = false) {
        if (param.undefined) {
            delete param.undefined;
        }
        let nodes = Object.values(param);
        let newNodes = [];
        nodes.forEach((node, i) => {
            let itemId = '.node_' + node.id;
            var nodeElement = this.stage.findOne(itemId);
            if (nodeElement == undefined) {
                let inputs = Object.values(node.inputs);
                let outputs = Object.values(node.outputs);
                let nodeStyle = {
                    source
                };
                switch (node.name) {
                    case 'simple_message':
                        nodeStyle = {
                            label: 'Mensagem simples',
                            icon: 'message'
                        }
                        break;
                    case 'flow_end':
                        nodeStyle = {
                            background: 'rgb(224, 215, 251)',
                            label: 'Finalizar fluxo',
                            icon: 'check',
                        };
                        break;
                    case 'template_whatsapp':
                        nodeStyle = {
                            background: 'rgb(118 255 219)',
                            label: 'Template',
                            icon: 'palette',
                        };
                        break;
                    case 'send_file':
                        nodeStyle = {
                            background: '#ffbf00a8',
                            label: 'Enviar arquivo',
                            icon: 'upload_file',
                        };
                        break;
                    case 'next_additional':
                        nodeStyle = {
                            background: '#afd8ff',
                            label: 'Retornar flow',
                            icon: 'redo',
                        };
                        break;
                    case 'response_expecter':
                        nodeStyle = {
                            background: 'rgb(157, 239, 225)',
                            label: 'Solicitar Dados',
                            icon: 'data_exploration',
                        };
                        break;
                    case 'branch':
                        nodeStyle = {
                            background: 'rgb(253, 240, 208)',
                            label: 'Condição',
                            icon: 'flaky',
                        };
                        break;
                    case 'add_variable':
                        nodeStyle = {
                            label: 'Variável',
                            icon: 'database'
                        };
                        break;
                    case 'contact_update':
                        nodeStyle = {
                            label: 'Atualizar Contato',
                            icon: 'person'
                        };
                        break;
                    case 'consumir_api':
                        nodeStyle = {
                            label: 'API',
                            icon: 'cloud_upload'
                        };
                        break;
                    case 'interactive_whatsapp':
                        nodeStyle = {
                            label: 'Interação',
                            icon: 'interactive_space'
                        };
                        break;
                    case 'loop_flow':
                        nodeStyle = {
                            label: 'Loop',
                            icon: 'all_inclusive'
                        };
                        break;
                    case 'catalog_whatsapp':
                        nodeStyle = {
                            label: 'Catátologo',
                            icon: 'storefront'
                        };
                        break;
                    case 'transfer_omni':
                        nodeStyle = {
                            label: 'Transferir Omni',
                            icon: 'sync_alt',
                            background: '#fcb6c0',
                        };
                        break;
                    case 'delay':
                        nodeStyle = {
                            label: 'Delay',
                            icon: 'schedule',
                            background: '#83f7a3',
                        };
                        break;
                    case 'deal':
                        nodeStyle = {
                            label: 'Deal',
                            icon: 'filter_alt',
                            background: '#b0d6ff',
                        };
                        break;
                    case 'rcs_card':
                        nodeStyle = {
                            label: 'Cartão',
                            icon: 'pages',
                            background: '#a8e2ff',
                        };
                        break;
                    case 'rcs_carousel':
                        nodeStyle = {
                            label: 'Carrossel',
                            icon: 'view_carousel',
                            background: '#a8e2ff',
                        };
                        break;
                    case 'yup_ai':
                        nodeStyle = {
                            label: 'YupAI',
                            color: '#000',
                            icon: '/assets/img/channels/ai.svg',
                            isSource: true,
                            background: '#fff',
                        };
                        break;
                    case 'opt_out':
                        nodeStyle = {
                            label: 'OptOut',
                            icon: 'person_off',
                            color: '#fff',
                            background: '#fc0356',
                        };
                        break;
                    default:
                        break;
                }

                let newNode = this.addNode(node.pos_x, node.pos_y, node.name, node.data, inputs.length, outputs.length, nodeStyle, node.id, node.outputs, node.inputs);
                newNodes[node.id] = newNode;
            }
        });
        if (old) {
            newNodes.forEach((node) => {
                if (old) {
                    let inputs = Object.values(node.inputs);
                    let outputs = Object.values(node.outputs);
                    inputs.forEach((input, i) => {
                        input.connections.forEach((connection, index) => {
                            let hasNull = true;
                            newNodes[connection.node].outputs[connection.input].connections.forEach((connection) => {
                                if (connection.node == node.id) {
                                    hasNull = false;
                                }
                            });
                            if (hasNull) {
                                newNodes[connection.node].outputs[connection.input].connections.push({
                                    node: node.id,
                                    output: 'input_1'
                                });
                                this.nodes[connection.node].outputs[connection.input].connections.push({
                                    node: node.id,
                                    output: 'input_1'
                                });
                            }
                        });
                    });

                    outputs.forEach((output, i) => {
                        output.connections.forEach((connection, index) => {
                            let hasNull = true;
                            newNodes[connection.node].inputs[connection.output].connections.forEach((connection) => {
                                if (connection.node == node.id) {
                                    hasNull = false;
                                }
                            });
                            if (hasNull) {
                                newNodes[connection.node].inputs[connection.output].connections.push({
                                    node: node.id,
                                    output: connection.output
                                });
                                this.nodes[connection.node].inputs[connection.output].connections.push({
                                    node: node.id,
                                    output: connection.output
                                });
                            }
                        });
                    });
                }
            });
        }
        newNodes.forEach((node) => {
            let inputs = Object.values(node.inputs);
            let outputs = Object.values(node.outputs);
            outputs.forEach((output, i) => {
                output.connections.forEach((connection, index) => {
                    let outputObj = this.stage.findOne(`.output_${output.index}_node_${node.id}`);
                    //input name
                    let inputObj = this.stage.findOne(`.${connection.output}_node_${connection.node}`);
                    outputObj.setAttr('fill', '#03fcd3');
                    if (inputObj) {
                        inputObj.setAttr('fill', '#03fcd3');
                        var outPutX = outputObj.getAttr('x') + outputObj.parent.getAttr('x');
                        var outPutY = outputObj.getAttr('y') + outputObj.parent.getAttr('y');
                        var inputX = inputObj.getAttr('x') + inputObj.parent.getAttr('x');
                        var inputY = inputObj.getAttr('y') + inputObj.parent.getAttr('y');

                        let points = [
                            outPutX,
                            outPutY,
                            inputX,
                            inputY,
                        ];
                        //check if exist
                        var lineStage = this.stage.findOne(`.node_in_${connection.node}_input_${inputObj.getAttr('index')}_node_out_${node.id}_output_${output.index}`);
                        if (lineStage == undefined || lineStage == null) {
                            var line = new Konva.Line({
                                name: `node_in_${connection.node}_input_${inputObj.getAttr('index')}_node_out_${node.id}_output_${output.index}`,
                                points: points,
                                stroke: '#E5E5E5',
                                strokeWidth: 4,
                                lineCap: 'round',
                                lineJoin: 'round',
                                fillPatternX: 10,
                            });
                            line.perfectDrawEnabled(false);
                            line.sceneFunc((context, shape) => {
                                this.lineCurve(inputX, outPutX, inputY, outPutY, context, shape);
                            });

                            this.connections.push({
                                id: line._id,
                                node_out: node.id,
                                line: line,
                                points: points,
                                output_index: output.index,
                                output_name: `output_${output.index}_node_${node.id}`,
                                input_name: `${connection.output}_node_${connection.node}`,
                                input_index: inputObj.getAttr('index'),
                                node_in: connection.node
                            });
                            this.layer.add(line);
                            line.zIndex(0);
                            line.on('mouseover', (e) => {
                                e.target.attrs.stroke = '#03fcd3';
                                line.zIndex(0);
                                e.target.draw();
                            });
                            line.on('click', (e) => {
                                if (!this.drawingLine) {
                                    let deleteConnection = this.stage.findOne(`.delete_line_${e.target._id}`);

                                    if (deleteConnection == undefined) {
                                        deleteConnection = this.makeDelete(e.target.getRelativePointerPosition().x, e.target.getRelativePointerPosition().y, `delete_line_${e.target._id}`);
                                        this.layer.add(deleteConnection);
                                    }
                                }
                            });
                            line.on('mouseout', function (e) {
                                e.target.attrs.stroke = '#E5E5E5';
                                line.zIndex(0);
                                e.target.draw();
                            });
                        }
                    }
                });
            })
        });
        this.generateSelect();
    }

    clear() {
        this.layer.removeChildren();
    }

    drawGrid() {
        this.gridLayer.clear();
        this.gridLayer.destroyChildren();
        this.gridLayer.clipWidth(null); // clear any clipping

        this.stageRect = {
            x1: 0,
            y1: 0,
            x2: this.stage.width(),
            y2: this.stage.height(),
            offset: {
                x: this.unScale(this.stage.position().x),
                y: this.unScale(this.stage.position().y),
            }
        };
        this.viewRect = {
            x1: -this.stageRect.offset.x,
            y1: -this.stageRect.offset.y,
            x2: this.unScale(this.width) - this.stageRect.offset.x,
            y2: this.unScale(this.height) - this.stageRect.offset.y
        };
        // and find the largest rectangle that bounds both the stage and view rect.
        // This is the rect we will draw on.
        this.fullRect = {
            x1: Math.min(this.stageRect.x1, this.viewRect.x1),
            y1: Math.min(this.stageRect.y1, this.viewRect.y1),
            x2: Math.max(this.stageRect.x2, this.viewRect.x2),
            y2: Math.max(this.stageRect.y2, this.viewRect.y2)
        };
        let gridOffset = {
            x: Math.ceil(this.unScale(this.stage.position().x) / this.stepSize) * this.stepSize,
            y: Math.ceil(this.unScale(this.stage.position().y) / this.stepSize) * this.stepSize,
        };
        let gridRect = {
            x1: -gridOffset.x,
            y1: -gridOffset.y,
            x2: this.unScale(this.width) - gridOffset.x + this.stepSize,
            y2: this.unScale(this.height) - gridOffset.y + this.stepSize
        };
        this.gridFullRect = {
            x1: Math.min(this.stageRect.x1, gridRect.x1),
            y1: Math.min(this.stageRect.y1, gridRect.y1),
            x2: Math.max(this.stageRect.x2, gridRect.x2),
            y2: Math.max(this.stageRect.y2, gridRect.y2)
        };

        // set clip function to stop leaking lines into non-viewable space.
        this.gridLayer.clip({
            x: this.viewRect.x1,
            y: this.viewRect.y1,
            width: this.viewRect.x2 - this.viewRect.x1,
            height: this.viewRect.y2 - this.viewRect.y1
        });

        let fullRect = this.gridFullRect;

        const
            // find the x & y size of the grid
            xSize = (fullRect.x2 - fullRect.x1),
            ySize = (fullRect.y2 - fullRect.y1),

            // compute the number of steps required on each axis.
            xSteps = Math.round(xSize / this.stepSize),
            ySteps = Math.round(ySize / this.stepSize);

        // draw vertical lines
        for (let i = 0; i <= xSteps; i++) {
            this.gridLayer.add(
                new Konva.Line({
                    x: fullRect.x1 + i * this.stepSize,
                    y: fullRect.y1,
                    points: [0, 0, 0, ySize],
                    stroke: '#E5E5E5',
                    strokeWidth: 1,
                })
            );
        }
        //draw Horizontal lines
        for (let i = 0; i <= ySteps; i++) {
            this.gridLayer.add(
                new Konva.Line({
                    x: fullRect.x1,
                    y: fullRect.y1 + i * this.stepSize,
                    points: [0, 0, xSize, 0],
                    stroke: '#E5E5E5',
                    strokeWidth: 1,
                })
            );
        }

        this.gridLayer.batchDraw();
    }

    dispatch(event, details) {
        // Check if this event not exists
        if (this.events[event] === undefined) {
            // console.error(`This event: ${event} does not exist`);
            return false;
        }
        this.events[event].listeners.forEach((listener) => {
            listener(details);
        });
    }

    isEmpty(item) {
        if (item == null || item == '' || item == undefined) {
            return true;
        } else {
            if (item.length == 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    async publishFlow(flow, callback) {
        try {
            Loader.showModal('Publicando...');
            let nodes = JSON.parse(flow.nodes);
            let draftNodes = JSON.parse(flow.nodes_draft);
            let response = null;

            if (flow.importing === true || flow.importing === 'true') {
                try {
                    response = await webApi.delete(`flows/delete-actions/${flow.id}`);
                } catch (error) {
                }
            } else {
                //Delete old nodes
                Loader.showModal('Removendo nodes antigos...');
                for (var i in draftNodes) {
                    let old = nodes[i];
                    let node = draftNodes[i];
                    if (old == undefined || old == null) {
                        await webApi.post(`flow/delete-action`, {
                            name: node.name,
                            node: node,
                            flow_id: flow.id,
                        });
                    }
                }
            }

            //Criando backs
            Loader.showModal('Atualizando back nodes...');
            for (var i in draftNodes) {
                let node = draftNodes[i];
                let backNode = draftNodes[node.data.back_node_id];
                if (backNode) {
                    if (backNode.data.action_id == null || backNode.data.action_id == '' || backNode.data.action_id == undefined || backNode.data.action_id.length == 0) {
                        if (node.back_id == "") {
                            delete node.back_name;
                            delete node.back_id;
                        }
                        switch (backNode.name) {
                            case 'loop_flow':
                                response = await this.createLoop(node.data.back_node_id, backNode, draftNodes[backNode.data.back_node_id], draftNodes, nodes, flow);
                                break;
                            default:
                                response = await webApi.post(`flow/action`, {
                                    node: backNode,
                                    flow_id: flow.id,
                                });
                        }
                        var node_id = null;
                        if (response.id) {
                            node_id = response.id;
                        } else {
                            node_id = response.data.id;
                        }
                        draftNodes[node.data.back_node_id].data.action_id = node_id;
                        draftNodes[i].data.back_id = node_id;
                    }
                }
            }


            //Make new nodes
            Loader.showModal('Criando novos nodes...');
            let consumir_apis = [];
            for (var i in draftNodes) {
                let node = draftNodes[i];
                let backNode = draftNodes[node.data.back_node_id];
                let first = node.data.first ? node.data.first : false;
                let to_link = first ? true : false;
                let data = node.data;
                var node_id = null;
                let backId = null;
                let backName = null;
                let action = {};

                if ((data.back_node_id != null || data.back_node_id != undefined) && data.first == false && backNode != undefined) {
                    backId = backNode.data.action_id;
                    backName = backNode.data.action_name ? backNode.data.action_name : null;

                    data.back_id = backId;
                    data.back_name = backName;
                }
                
                switch (node.name) {
                    case 'consumir_api':
                        consumir_apis.push(draftNodes[i]);
                        break;
                    default:
                        break;
                }

                if (this.isEmpty(node.data.action_id)) {
                    response = await webApi.post(`flow/action`, {
                        node: node,
                        flow_id: flow.id,
                    });

                    if (response.id != undefined) {
                        node_id = response.id;
                    } else {
                        node_id = response.data.id;
                    }

                    draftNodes[i].data.action_id = node_id;
                    draftNodes[i].data.back_id = backId;
                    draftNodes[i].data.back_name = backName;
                } else {
                    draftNodes[i].data.back_id = backId;
                    draftNodes[i].data.back_name = backName;
                    if (node.name != 'loop_flow') {
                        response = await webApi.post(`flow/update-action`, {
                            node: node,
                            flow_id: flow.id,
                        });

                        if (response.id != undefined) {
                            node_id = response.id;
                        } else {
                            node_id = response.data.id;
                        }
                        draftNodes[i].data.back_id = backId;
                        draftNodes[i].data.back_name = backName;
                    }
                }
            }

            //Remake interactive and Template
            Loader.showModal('Criando nodes interativos...');
            for (var i in draftNodes) {
                let node = draftNodes[i];
                let backNode = draftNodes[node.data.back_node_id];
                let first = node.data.first ? node.data.first : false;
                let to_link = first ? true : false;
                let data = node.data;
                var node_id = null;
                let backId = null;
                let backName = null;
                let action = {};
                let response = null;

                if ((data.back_node_id != null || data.back_node_id != undefined) && data.first == false && backNode != undefined) {
                    backId = backNode.data.action_id;
                    backName = backNode.data.action_name ? backNode.data.action_name : null;

                    data.back_id = backId;
                    data.back_name = backName;
                }

                switch (node.name) {
                    case 'catalog_whatsapp':
                        response = await webApi.post(`flow/delete-action`, {
                            node: node,
                            flow_id: flow.id,
                        });

                        response = await webApi.post(`flow/action`, {
                            node: node,
                            flow_id: flow.id,
                        });

                        if (response.id != undefined) {
                            node_id = response.id;
                        } else {
                            node_id = response.data.id;
                        }
                        draftNodes[i].data.action_id = node_id;
                        draftNodes[i].data.back_id = backId;
                        draftNodes[i].data.back_name = backName;
                        node.data.action_id = node_id;
                        data.action_id = node_id;
                        break;
                    case 'interactive_whatsapp':
                        if (data.template.type == 'buttons') {
                            data.template.sections = null;
                        }
                        if (data.template.type == 'menu') {
                            data.template.buttons = null;
                        }

                        response = await webApi.post(`flow/delete-action`, {
                            node: node,
                            flow_id: flow.id,
                        });

                        response = await webApi.post(`flow/action`, {
                            node: node,
                            flow_id: flow.id,
                        });

                        if (response.id != undefined) {
                            node_id = response.id;
                        } else {
                            node_id = response.data.id;
                        }
                        draftNodes[i].data.action_id = node_id;
                        draftNodes[i].data.back_id = backId;
                        draftNodes[i].data.back_name = backName;
                        node.data.action_id = node_id;
                        data.action_id = node_id;
                        break;
                    case 'template_whatsapp':
                        action = {
                            'first': first,
                            'to_link': to_link,
                            'flow_id': flow.id,
                            'main_user': true,
                            'key': data.key,
                            'language_policy': 'deterministic',
                            'language_code': this.isEmpty(data.template.language_code) ? data.template.language_code : 'PT_BR',
                            'template_name': data.template.name,
                            'class_name_back': this.isEmpty(data.back_name) ? data.back_name : null,
                            'header_link': this.isEmpty(data.template.header_link) ? data.template.header_link : null,
                            'back': this.isEmpty(data.back_id) ? data.back_id : null
                        };
                        let parameters = [];
                        if (this.isEmpty(data.template.params)) {
                            for (var param in data.template.params) {
                                parameters.push({
                                    'value': param.text,
                                    'sort': param.index,
                                    'type': 'text',
                                })
                            }
                        }
                        action.parameters = parameters;
                        await webApi.post(`flow/delete-action`, {
                            node: node,
                            flow_id: flow.id,
                        });

                        response = await webApi.post(`flow/action`, {
                            node: node,
                            flow_id: flow.id,
                        });
                        if (response.id != undefined) {
                            node_id = response.id;
                        } else {
                            node_id = response.data.id;
                        }
                        draftNodes[i].data.action_id = node_id;
                        draftNodes[i].data.back_id = backId;
                        draftNodes[i].data.back_name = backName;
                        break;
                }
            }

            for (var i in consumir_apis) {
                let node = consumir_apis[i];
                let backNode = consumir_apis[node.data.back_node_id];
                let first = node.data.first ? node.data.first : false;
                let to_link = first ? true : false;
                let data = node.data;
                var node_id = null;
                let backId = null;
                let backName = null;
                let action = {};
                let response = null;

                if ((data.back_node_id != null || data.back_node_id != undefined) && data.first == false && backNode != undefined) {
                    backId = backNode.data.action_id;
                    backName = backNode.data.action_name ? backNode.data.action_name : null;

                    data.back_id = backId;
                    data.back_name = backName;
                }
                action = {
                    'first': first,
                    'flow_id': flow.id,
                    'to_link': to_link,
                    'main_user': true,
                    'key': data.key,
                    'class_name_back': this.isEmpty(data.back_name) ? data.back_name : null,
                    'back': this.isEmpty(data.back_id) ? data.back_id : null,
                    'auth': data.auth,
                    'end_point': data.end_point,
                    'method': data.method,
                    'type_auth': data.type_auth,
                    'parameters_body': this.isEmpty(data.parameters_body) ? data.parameters_body : [],
                    'json_body': this.isEmpty(data.json_body) ? data.json_body : '',
                    'parameters_path': this.isEmpty(data.parameters_path) ? data.parameters_path : [],
                };

                if (data.failed_action_node_id != null && data.failed_action_node_id != undefined) {
                    if (draftNodes[data.failed_action_node_id] != null && draftNodes[data.failed_action_node_id] != undefined) {
                        node.data.failed_action_id = draftNodes[data.failed_action_node_id].data.action_id;
                        node.data.failed_class_name = draftNodes[data.failed_action_node_id].data.action_name;
                    }
                }

                response = await webApi.post(`flow/delete-action`, {
                    node: node,
                    flow_id: flow.id,
                });

                response = await webApi.post(`flow/action`, {
                    node: node,
                    flow_id: flow.id,
                });

                if (response.id != undefined) {
                    node_id = response.id;
                } else {
                    node_id = response.data.id;
                }
                consumir_apis[i].data.action_id = node_id;
                consumir_apis[i].data.back_id = backId;
                consumir_apis[i].data.back_name = backName;
            }
            //Make loop
            Loader.showModal('Criando loops...');
            for (var i in draftNodes) {
                let node = draftNodes[i];
                let backNode = draftNodes[node.data.back_node_id];
                switch (node.name) {
                    case 'loop_flow':
                        await this.createLoop(i, node, backNode, draftNodes, nodes, flow);
                        break;
                    default:
                }
            }

            //Update controllers
            Loader.showModal('Atualizando controllers...');
            for (var i in draftNodes) {
                let controllerId = null;
                let controllerName = null;
                let currentController = null;
                let node = draftNodes[i]
                let data = node.data;
                if (!this.isEmpty(data.controller_id) && data.first == false && !this.isEmpty(draftNodes[data.controller_id])) {
                    controllerId = draftNodes[data.controller_id].data.action_id;
                    controllerName = !this.isEmpty(draftNodes[data.controller_id].data.action_name) ? draftNodes[data.controller_id].data.action_name : null;
                    currentController = !this.isEmpty(data.current_controller) ? data.current_controller : null;
                }

                if (!this.isEmpty(data.action_id) && !this.isEmpty(controllerName) && !this.isEmpty(controllerId)) {
                    draftNodes[i].data.controller = currentController ? controllerId : null;
                    draftNodes[i].data.class_name_controller = currentController ? controllerName : null;
                    node.data.controller = currentController ? controllerId : null;
                    node.data.class_name_controller = currentController ? controllerName : null;
                    if (node.name != 'loop_flow') {
                        response = await webApi.post(`flow/update-action`, {
                            node: node,
                            flow_id: flow.id,
                        });
                    }
                }
            }

            for (var i in draftNodes) {
                let node = draftNodes[i];
                if (node.name == 'branch' && !this.isEmpty(node.data.otherwise_id)) {
                    if (draftNodes[node.data.otherwise_id] && node.data.action_id) {
                        node.data.otherwise = draftNodes[node.data.otherwise_id].data.action_id;

                        response = await webApi.post(`flow/update-action`, {
                            node: node,
                            flow_id: flow.id,
                        });
                        draftNodes[i].data.otherwise = draftNodes[node.data.otherwise_id].data.action_id;
                    }
                }
            }

            Loader.showModal('Limpando nodes...');
            //Clear connections
            response = await webApi.post(`flow/clear-connections`, {
                flow_id: flow.id,
            });

            for (var i in draftNodes) {
                let node = draftNodes[i];
                let data = node.data;
                let currentNode = null;
                let nextNodeId = null;

                if (node.name != 'welcome' && node.name != 'flow_end') {
                    if (!this.isEmpty(draftNodes[i].outputs.output_1)) {
                        for (var connection in draftNodes[i].outputs.output_1.connections) {
                            let con = draftNodes[i].outputs.output_1.connections[connection];
                            currentNode = draftNodes[i];
                            nextNodeId = con.node;
                            if (!this.isEmpty(draftNodes[nextNodeId])) {
                                let nextNode = draftNodes[nextNodeId];
                                if (currentNode.data.action_name != 'LoopFlow') {

                                    let action = {
                                        'current_class_name': currentNode.data.action_name,
                                        'flow_action_id': currentNode.data.action_id,
                                        'flow_action_id_next': nextNode.data.action_id,
                                        'next_class_name': nextNode.data.action_name,
                                    };
                                    let response = await webApi.post(`flow/get-additional`, {
                                        flow_action_id: action.flow_action_id,
                                        flow_action_id_next: action.flow_action_id_next,
                                    });

                                    if (this.isEmpty(response.data) || this.isEmpty(response.id)) {
                                        if (response.data.length == 0) {
                                            try {
                                                response = await webApi.post(`flow/next-additional`, {
                                                    flow_id: flow.id,
                                                    node: action,
                                                });
                                            } catch (error) {
                                                console.log('Erro node: ' + error.message);
                                                console.log(node);
                                            }
                                        }
                                    }
                                }
                            } else {
                                console.log('Não existe');
                                console.log(nextNodeId);
                            }
                        }
                    }

                    if (node.name != 'branch') {
                        if (!this.isEmpty(draftNodes[i].outputs.output_2)) {
                            for (var connection in draftNodes[i].outputs.output_2.connections) {
                                let con = draftNodes[i].outputs.output_2.connections[connection];
                                currentNode = draftNodes[i];
                                nextNodeId = con.node;
                                if (!this.isEmpty(draftNodes[nextNodeId])) {
                                    let nextNode = draftNodes[nextNodeId];
                                    let action = {
                                        'current_class_name': currentNode.data.action_name,
                                        'flow_action_id': currentNode.data.action_id,
                                        'flow_action_id_next': nextNode.data.action_id,
                                        'next_class_name': nextNode.data.action_name,
                                    };
                                    let response = await webApi.post(`flow/get-additional`, {
                                        flow_action_id: action.flow_action_id,
                                        flow_action_id_next: action.flow_action_id_next,
                                    });
                                    if (this.isEmpty(response.data) || this.isEmpty(response.id)) {
                                        if (response.data.length == 0) {
                                            try {
                                                response = await webApi.post(`flow/next-additional`, {
                                                    flow_id: flow.id,
                                                    node: action,
                                                });
                                            } catch (error) {
                                                console.log('Erro node: ' + error.message);
                                                console.log(node);
                                            }
                                        }
                                    }
                                } else {
                                    console.log('Não existe');
                                    console.log(nextNodeId);
                                }
                            }
                        }
                    }
                }
            }
            Loader.hide();
            callback(draftNodes);
        } catch (error) {
            console.log('Erro ao publicar flow');
            console.log(error);
            Loader.hide();
            callback(null, error);
        }
    }
    /*
      @i posicao do node no loop
      @node o node que precisa ser registrado
      @backNode representa o node pai do elemento
    */
    async createLoop(i, node, backNode, draftNodes, nodes, flow) {
        let response = null;
        let backId = null;
        let backName = null;
        const data = node.data;
        let first = this.isEmpty(data.first) ? data.first : false;
        let to_link = first ? true : false;
        if ((data.back_node_id != null || data.back_node_id != undefined) && data.first == false) {
            backId = backNode.data.action_id;
            backName = backNode.data.action_name ? backNode.data.action_name : null;

            data.back_id = backId;
            data.back_name = backName;
        }
        if (this.isEmpty(data.action_id)) {
            node.data = {
                ...node.data,
                'to_link': to_link,
                'first': first,
                'flow_id': flow.id,
                'main_user': true,
                'send_action': draftNodes[data.send_action_id].data.action_id,
                'class_name_action': draftNodes[data.send_action_id].data.action_name,
                'attribute': this.isEmpty(data.attribute) ? data.attribute : '',
                'key': data.key,
                'class_name_back': this.isEmpty(data.back_name) ? data.back_name : null,
                'back': this.isEmpty(data.back_id) ? data.back_id : null,
                'wait_finish': true
            };

            response = await webApi.post(`flow/action`, {
                node: node,
                flow_id: flow.id,
            });

            if (this.isEmpty(response.id)) {
                draftNodes[i].data.action_id = response.id;
                draftNodes[i].data.back_id = backId;
                draftNodes[i].data.back_name = backName;
                draftNodes[i].data.send_action = draftNodes[data.send_action_id].data.action_id;
                draftNodes[i].data.class_name_action = draftNodes[data.send_action_id].data.action_name;
            }
        } else {
            draftNodes[i].data.back_id = backId;
            draftNodes[i].data.back_name = backName;
            draftNodes[i].data.send_action = draftNodes[data.send_action_id].data.action_id;
            draftNodes[i].data.class_name_action = draftNodes[data.send_action_id].data.action_name;
            response = await webApi.post(`flow/update-action`, {
                node: draftNodes[i],
                flow_id: flow.id,
            });
            draftNodes[i].data.action_id = draftNodes[i].data.action_id;
        }
        return response;
    }

    validate() {
        let hasError = false;
        let erroNext = false;
        this.nodesError = [];
        this.errorsShapes.forEach((node) => {
            node.remove();
        });

        for (var nodeId in this.nodes) {
            let node = this.nodes[nodeId];
            let hasErrorCheck = false;
            let itemId = '.node_' + nodeId;
            var nodeElement = this.stage.findOne(itemId);
            if (nodeElement != undefined) {
                switch (node.name) {
                    case 'simple_message':
                        //check if has message
                        if (node.data.text.length == 0) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Esse node está desconectado   ');
                            nodeElement.add(shapeError);
                            this.errorsShapes.push(shapeError);

                            hasError = true;
                            hasErrorCheck = true;
                        }
                        break;
                    case 'loop_flow':
                        //check if loop has the exit action
                        if (node.data.send_action_id.length == 0) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Ação de saída é necessária   ');
                            nodeElement.add(shapeError);
                            this.errorsShapes.push(shapeError);

                            hasError = true;
                            hasErrorCheck = true;
                        }
                        if (node.data.attribute == null || node.data.attribute.length == 0 || node.data.attribute == '' || node.data.attribute == undefined) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'O atributo precisa ser preenchidos   ');
                            nodeElement.add(shapeError);
                            this.errorsShapes.push(shapeError);

                            hasError = true;
                            hasErrorCheck = true;
                        }
                        break;
                    case 'contact_update':
                        //check if has message
                        if (node.data.field.length == 0 || node.data.value.length == 0) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'O campo ou atributo precisam ser preenchidos   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'send_file':
                        //check if has file
                        if (node.data.url == null || node.data.url == undefined || node.data.url.length == 0) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Precisa de um arquivo   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'response_expecter':
                        //check if has message
                        if (node.data.variable_name == null || node.data.variable_name.length == 0 || node.data.variable_name == '') {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'O nome da variável precisa ser preenchidos   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'branch':
                        //check if has message
                        if (node.data.attribute == null || node.data.attribute.length == 0 || node.data.attribute == '') {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'O nome da variável precisa ser preenchidos   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'add_variable':
                        //check if has message
                        if (node.data.name.length == 0 || node.data.value.length == 0) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'O nome e valor precisam ser preenchidos   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'catalog_whatsapp':
                        //check if has message
                        if (node.data.catalog == null || (node.data.catalog != null && node.data.catalog.catalog_id.length == 0)) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Você precisa escolher um catálogo   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'template_whatsapp':
                        //check if has message
                        if (node.data.template == null || (node.data.template != null && node.data.template.name.length == 0)) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Você precisa escolher um template   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'interactive_whatsapp':
                        //check if has message
                        if (node.data.template == null || (node.data.template != null && node.data.template.body.length == 0)) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Você precisa preencher os dados   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        if (node.data.template) {
                            if (node.data.template.type == 'buttons' && node.data.template.buttons.length == 0) {
                                let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Você precisa adicionar botões  ');
                                nodeElement.add(shapeError);
                                hasError = true;
                                hasErrorCheck = true;
                                this.errorsShapes.push(shapeError);
                            }
                        }
                        break;
                    case 'transfer_omni':
                        //check if has message
                        if (node.data.department.length == 0 || node.data.transfer_message == null) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Você precisa escolher um departamento e uma mensagem padrão   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'consumir_api':
                        //check if has message
                        if (node.data.end_point.length == 0) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Você precisa informar o EndPoint   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'deal':
                        //check if has message
                        if (node.data.stage == null) {
                            let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Você precisa informar o estágio   ');
                            nodeElement.add(shapeError);
                            hasError = true;
                            hasErrorCheck = true;
                            this.errorsShapes.push(shapeError);
                        }
                        break;
                    case 'loop_flow':
                        if (node.outputs) {
                            if (node.outputs.output_1) {
                                if (node.outputs.output_1.connections.length == 0) {
                                    isAlone = true;
                                    this.nodesError.push(node);
                                }
                            }
                            if (node.outputs.output_2) {
                                if (node.outputs.output_2.connections.length == 0) {
                                    isAlone = true;
                                    this.nodesError.push(node);
                                }
                            }
                        }
                        break;
                    case 'next_additional':
                        if (node.inputs) {
                            if (node.inputs.input_1) {
                                if (node.inputs.input_1.connections.length > 0) {
                                    node.inputs.input_1.connections.forEach((con) => {
                                        console.log('Node Con', this.nodes[con.node]);
                                        if(this.nodes[con.node]) {
                                            if(this.nodes[con.node].name == 'branch' && con.input == 'output_2') {
                                                let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Esse node não pode ser conectado na saída 2 da Condição   ');
                                                nodeElement.add(shapeError);
                                                hasError = true;
                                                hasErrorCheck = true;
                                                this.errorsShapes.push(shapeError);
                                                erroNext = true;
                                            }
                                        }
                                    });
                                }
                            }
                        }
                        break;
                }

                //check if this is alone
                let isAlone = false;
                let isSame = false;
                if (node.inputs.input_1) {
                    if (node.inputs.input_1.connections.length == 0) {
                        isAlone = true;
                        this.nodesError.push(node);
                    }
                }
                if (node.outputs) {
                    if (node.outputs.output_1) {
                        if (node.outputs.output_1.connections.length == 0) {
                            isAlone = true;
                            this.nodesError.push(node);
                        } else {
                            if (node.inputs.input_1) {
                                if (node.inputs.input_1.connections.length > 0) {
                                    if (parseInt(node.outputs.output_1.connections[0].node) == parseInt(node.inputs.input_1.connections[0].node)) {
                                        isSame = true
                                    }
                                }
                            }
                        }
                    }
                }

                if (isAlone) {
                    let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Esse node está sem conexão   ');
                    nodeElement.add(shapeError);
                    hasError = true;
                    hasErrorCheck = true;
                    this.errorsShapes.push(shapeError);
                }

                if (isSame) {
                    let shapeError = this.makeMessage(nodeElement.children[0].getAttr('width') / 2, -10, 'Esse node está repetido, desconecte e reconecte o node   ');
                    nodeElement.add(shapeError);
                    hasError = true;
                    hasErrorCheck = true;
                    this.errorsShapes.push(shapeError);
                }
            }

        }

        return hasError;
    }
}
